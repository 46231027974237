import Vue from 'vue';
import { currentStoreView, localizedRoute } from '@vue-storefront/core/lib/multistore'
import axios from 'axios';
import config from 'config';
import Cookies from 'js-cookie';
import VueGtm from '@gtm-support/vue2-gtm';
import { router } from '@vue-storefront/core/app';
import {
  fetchCart,
  updateCart,
  removeMutation,
  addSimpleMutation,
  addConfigurableMutation,
  applyCouponCodeMutation,
  removeCouponCodeMutation
} from './magento-queries/graphql-cart';

function googleTagManagerEventAddToCart(product, currencyCode) {
  const GTM: typeof VueGtm = (Vue as any).gtm;
  GTM.trackEvent({
    event: 'addToCart',
    ecommerce: {
      currencyCode,
      add: {
        products: [product]
      }
    }
  });
}

function googleTagManagerEventRemoveFromCart(product) {
  const GTM: typeof VueGtm = (Vue as any).gtm;
  GTM.trackEvent({
    event: 'removeFromCart',
    ecommerce: {
      remove: {
        products: [product]
      }
    }
  });
}

function checkCartError(res) {
  const cartError = res.data.data.addProductsToCart.user_errors;
  if (cartError && cartError.length) {
    return cartError[0].message;
  }
  return null;
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
function formatPrice(price: number, locale: string, currency: string) {
  if (!price || !locale || !currency) return 0;
  const formattedPrice = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency
  }).format(price);
  return formattedPrice;
}

const m2GraphqlUrl = config.m2GraphqlUrl;

export function formatToGraphql(arrayObjectData: any) {
  if (!arrayObjectData || !arrayObjectData.length) return;

  const cleaned = JSON.stringify(arrayObjectData, null, 2);
  return cleaned.replace(/^[\t ]*"(?:[^:\n\r\\]|\\[^])*":/gm, function (match) {
    return match.replace(/"/g, '');
  });
}

function setCartId(cartId: string) {
  Cookies.set('cart_id', cartId, {
    expires: 7,
    secure: true,
    sameSite: 'strict'
  });
}

export const graphqlCartStore = {
  namespaced: true,
  state: {
    cart: null,
    error: '',
    loading: false,
    displayCart: false,
    tempGuestCartId: ''
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    CLEAR_CART_ID() {
      Cookies.remove('cart_id');
    },
    SET_TEMP_GUEST_CART_ID(state, cartId) {
      state.tempGuestCartId = cartId;
    },
    CLEAR_TEMP_GUEST_CART_ID(state) {
      state.tempGuestCartId = '';
    },
    SET_CART(state, cart) {
      state.cart = cart;
    },
    SET_CART_OPEN(state) {
      state.displayCart = true;
      document.body.classList.add('menu-open');
    },
    TOGGLE_CART_DISPLAY(state) {
      state.displayCart = !state.displayCart;
      if (state.displayCart) {
        document.body.classList.add('menu-open');
      } else {
        document.body.classList.remove('menu-open');
      }
    }
  },
  actions: {
    checkCartIdErrorMessage({ commit }, errorMessage: string) {
      // don't throw error if cart id is not found
      if (
        errorMessage ===
        `The current user cannot perform operations on cart "${Cookies.get(
          'cart_id'
        )}"`
      ) {
        Cookies.remove('cart_id');
        commit('SET_CART', null);
        return;
      }
      // don't throw error if cart id is not found
      if (
        errorMessage ===
        `Could not find a cart with ID "${Cookies.get('cart_id')}"`
      ) {
        Cookies.remove('cart_id');
        commit('SET_CART', null);
        return;
      }

      if (errorMessage === `Could not find a cart with ID "undefined"`) {
        Cookies.remove('cart_id');
        commit('SET_CART', null);
        return;
      }

      if (errorMessage === `The cart isn't active.`) {
        Cookies.remove('cart_id');
        commit('SET_CART', null);
        return;
      }

      throw new Error(errorMessage);
    },
    async fetchData({ commit, dispatch, rootGetters }, query: string) {
      commit('SET_ERROR', '');
      const axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
          'Content-Currency': rootGetters['esw/getUserCurrency'],
          'ESW-Country': rootGetters['esw/getUserCountry'],
          'Store': currentStoreView().storeCode
        }
      };
      const token = Cookies.get('customer_token') as string | undefined;
      const url = m2GraphqlUrl; // config.m2GraphqlUrl;
      if (token && !axiosConfig.headers) {
        // @ts-ignore
        axiosConfig.headers = {};
      }
      if (token && axiosConfig.headers) {
        // @ts-ignore
        axiosConfig.headers.Authorization = `Bearer ${token}`;
      }
      const res = await axios.post(url, { query }, axiosConfig);

      if (res.data.errors && res.data.errors.length) {
        dispatch('checkCartIdErrorMessage', res.data.errors[0].message);
      }
      return res;
    },
    async fetchCart({ commit, dispatch }) {
      try {
        commit('SET_ERROR', '');
        commit('SET_LOADING', true);
        await dispatch('graphqlAccount/checkCustomerToken', null, {
          root: true
        });
        const existingCartId = Cookies.get('cart_id');
        if (!existingCartId) {
          commit('SET_CART', null);
          return;
        }
        const query = fetchCart(existingCartId);
        const res = await dispatch('fetchData', query);
        const cart = res.data.data.cart;
        commit('SET_CART', cart);
        return cart;
      } catch (error) {
        commit('SET_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async createCart({ dispatch, rootGetters }) {
      if (rootGetters['graphqlAccount/isLoggedIn']) {
        await dispatch('createAuthCart');
        return;
      }
      await dispatch('createGuestCart');
    },
    async createAuthCart({ commit, dispatch }) {
      try {
        commit('SET_ERROR', '');
        commit('SET_LOADING', true);
        const query = `
          query {
            customerCart {
              id
            }
          }
        `;
        const res = await dispatch('fetchData', query);
        const cartId = res.data.data.customerCart.id;
        setCartId(cartId);
      } catch (error) {
        commit('SET_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async createGuestCart({ commit, dispatch }) {
      try {
        commit('SET_ERROR', '');
        commit('SET_LOADING', true);
        const query = `
          mutation {
            createEmptyCart
          }
        `;
        const existingCartId = Cookies.get('cart_id');
        if (existingCartId) return;
        const res = await dispatch('fetchData', query);
        const cartId = res.data.data.createEmptyCart;
        setCartId(cartId);
      } catch (error) {
        commit('SET_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async addItemToCart({ commit, dispatch, rootGetters }, payload) {
      try {
        commit('SET_LOADING', true);
        commit('SET_ERROR', '');
        await dispatch('graphqlAccount/checkCustomerToken', null, {
          root: true
        });
        await dispatch('createCart');
        const cartItems = formatToGraphql(payload.cartItems);
        const cartId = Cookies.get('cart_id');
        const currency = rootGetters['esw/getUserCurrency'];
        const fwdToUpsell = payload.fwdToUpsell || false;
        let query = addSimpleMutation(cartId, cartItems);
        let cart = null;
        if (payload.isConfigurable) {
          query = addConfigurableMutation(cartId, cartItems);
        }
        const res = await dispatch('fetchData', query);
        const cartError = checkCartError(res);
        if (cartError) {
          commit('SET_CART_OPEN');
          throw new Error(cartError);
        }
        if (payload.isConfigurable) {
          cart = res.data.data.addConfigurableProductsToCart
            ? res.data.data.addConfigurableProductsToCart.cart
            : null;
        } else {
          cart = res.data.data.addProductsToCart
            ? res.data.data.addProductsToCart.cart
            : null;
        }
        commit('SET_CART', cart);
        googleTagManagerEventAddToCart(payload, currency);
        if (fwdToUpsell === true) {
          const url = `/${payload.bike}/review/`;
          window.location.href = url;
        } else {
          commit('SET_CART_OPEN');
        }
      } catch (error) {
        commit('SET_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async applyCouponCode({ commit, dispatch }, couponCode: string) {
      try {
        commit('SET_LOADING', true);
        commit('SET_ERROR', '');
        await dispatch('graphqlAccount/checkCustomerToken', null, {
          root: true
        });
        const cartId = Cookies.get('cart_id');
        const query = applyCouponCodeMutation(cartId, couponCode);
        const res = await dispatch('fetchData', query);
        const cart = res.data.data.applyCouponToCart.cart;
        commit('SET_CART', cart);
      } catch (error) {
        commit('SET_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async removeCouponCode({ commit, dispatch }) {
      try {
        commit('SET_LOADING', true);
        commit('SET_ERROR', '');
        await dispatch('graphqlAccount/checkCustomerToken', null, {
          root: true
        });
        const cartId = Cookies.get('cart_id');
        const query = removeCouponCodeMutation(cartId);
        const res = await dispatch('fetchData', query);
        const cart = res.data.data.removeCouponFromCart.cart;
        commit('SET_CART', cart);
      } catch (error) {
        commit('SET_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async removeItemFromCart({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true);
        commit('SET_ERROR', '');
        await dispatch('graphqlAccount/checkCustomerToken', null, {
          root: true
        });
        const cartId = Cookies.get('cart_id');
        const query = removeMutation(cartId, payload.cartItemId);
        const res = await dispatch('fetchData', query);
        const cart = res.data.data.removeItemFromCart.cart
          ? res.data.data.removeItemFromCart.cart
          : null;
        commit('SET_CART', cart);
        googleTagManagerEventRemoveFromCart(payload);
      } catch (error) {
        commit('SET_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async mergeCarts({ commit, dispatch, getters }) {
      // merges a guest cart with an auth cart
      const authCartId = Cookies.get('cart_id');
      // guest cart id is created when user logs in (see account store)
      const guestCartId = getters.getTempGuestCartId;
      if (!guestCartId || !authCartId) return;
      if (guestCartId === authCartId) return;
      try {
        commit('SET_ERROR', '');
        commit('SET_LOADING', true);
        const query = `
          mutation {
            mergeCarts(
              source_cart_id: "${guestCartId}"
              destination_cart_id: "${authCartId}"
            ) {
              id
            }
          }
        `;
        await dispatch('fetchData', query);
        await dispatch('setTempGuestCartId', '');
      } catch (error) {
        commit('SET_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async updateCartItems({ commit, dispatch }, payload) {
      try {
        commit('SET_ERROR', '');
        commit('SET_LOADING', true);
        await dispatch('graphqlAccount/checkCustomerToken', null, {
          root: true
        });
        const cartId = Cookies.get('cart_id');
        const query = updateCart(cartId, payload.cartItemUid, payload.quantity);
        const res = await dispatch('fetchData', query);
        const cart = res.data.data.updateCartItems.cart;
        commit('SET_CART', cart);
        // TOD0 - add to gtm
      } catch (error) {
        commit('SET_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async clearCart({ commit, dispatch }) {
      try {
        commit('SET_ERROR', '');
        commit('SET_LOADING', true);
        await dispatch('graphqlAccount/checkCustomerToken', null, {
          root: true
        });
        const cartId = Cookies.get('cart_id');
        const query = `
          mutation {
            clearCart(input: {uid: "${cartId}"}) {
              cart {
                id
              }
              errors {
                type
                message
              }
            }
          }
        `;
        await dispatch('fetchData', query);
        commit('SET_CART', null);
      } catch (error) {
        commit('SET_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    setTempGuestCartId({ commit }, cartId: string) {
      commit('SET_TEMP_GUEST_CART_ID', cartId);
    },
    clearCartId({ commit }) {
      commit('CLEAR_CART_ID');
      commit('CLEAR_TEMP_GUEST_CART_ID');
      commit('SET_CART', null);
    },
    clearCustomerToken({ commit }) {
      commit('CLEAR_CUSTOMER_TOKEN');
    },
    toggleCart({ commit, dispatch, getters }) {
      if (getters.getCartDisplay === false) {
        dispatch('fetchCart');
      }
      commit('TOGGLE_CART_DISPLAY');
    }
  },
  getters: {
    getCartItems: (state) => {
      return state.cart && state.cart.items ? state.cart.items : [];
    },
    getCCItems: (state, getters) => {
      return getters.getCartItems.filter((item) => item.associated_uid);
    },
    getCartBikes: (state, getters) => {
      return getters.getCartItems.filter(
        (item) =>
          item.associated_uid === null &&
          item.product.__typename === 'BundleProduct'
      );
    },
    getCartBikeCount: (state, getters) => {
      const qty = getters.getCartBikes.length
        ? getters.getCartBikes.map((item) => item.quantity)
        : 0;
      return qty ? qty.reduce((a, b) => a + b, 0) : 0;
    },
    getLastBikeInCart: (state, getters) => {
      if (!getters.getCartBikes.length) return null;
      return getters.getCartBikes[getters.getCartBikes.length - 1];
    },
    getCCUids: (state, getters) => {
      return getters.getCCItems.map((item) => item.associated_uid);
    },
    getCartItemsNoCC: (state, getters) => {
      if (!getters.getCCItems.length) return getters.getCartItems;
      const newCartItems = [];
      getters.getCCUids.forEach((ccUid) => {
        // get the bike with custom colour item
        const ccBike = getters.getCartBikes.find((item) => item.uid === ccUid);
        const ccItem = getters.getCCItems.find(
          (item) => item.associated_uid === ccUid
        );
        if (!ccBike || !ccItem) return;
        getters.getCartItems.forEach((cartItem) => {
          if (cartItem.uid === ccUid) {
            const newItem = { ...cartItem };
            // move the custom colour to the bike
            newItem.customColour = ccItem;
            // add the price of the custom colour to the main item
            const bikePrice = ccBike.prices.price_including_tax.value;
            const ccPrice = ccItem.prices.price_including_tax.value;
            newItem.prices.price_including_tax.value = bikePrice + ccPrice;
            newCartItems.push(newItem);
          } else {
            newCartItems.push(cartItem);
          }
        });
      });
      return newCartItems.filter(
        (item) => item.product.sku.includes('BBCOLOUR') === false
      );
    },
    getCartErrors: (state) => state.error,
    getTempGuestCartId: (state) => state.tempGuestCartId,
    getCartSubtotal: (state, getters) => {
      function getCartSubtotal(prices) {
        return prices.subtotal_including_tax.value;
      }
      const currency = getters.getCartCurrency;
      const locale = getters.getCartLocale;
      const price =
        state.cart && state.cart.prices
          ? getCartSubtotal(state.cart.prices)
          : 0;
      return formatPrice(price, locale, currency);
    },
    getRawShippingPrice: (state) => {
      function getCartShipping(prices) {
        return prices.default_shipping_rate.value;
      }
      const price =
        state.cart && state.cart.prices
          ? getCartShipping(state.cart.prices)
          : 0;
      return price;
    },
    getCartShippingPrice: (state, getters) => {
      const currency = getters.getCartCurrency;
      const locale = getters.getCartLocale;
      const price = getters.getRawShippingPrice;
      return formatPrice(price, locale, currency);
    },
    getCartTotal(state, getters) {
      function getCartTotal(prices) {
        // Apply discounts to the subtotal including tax
        const subtotalIncludingTax = prices.subtotal_including_tax.value;
        const discount = prices.discounts && prices.discounts[0] && prices.discounts[0].amount && prices.discounts[0].amount.value || 0;
        const totalAfterDiscount = subtotalIncludingTax - discount;

        return totalAfterDiscount; // Return total after applying discounts
      }
      const currency = getters.getCartCurrency;
      const locale = getters.getCartLocale;
      const cartTotal =
        state.cart && state.cart.prices ? getCartTotal(state.cart.prices) : 0;
      const shipping = getters.getRawShippingPrice;
      const totalWithSipping = cartTotal + shipping;
      return formatPrice(totalWithSipping, locale, currency);
    },
    getCartDisplay: (state) => state.displayCart,
    getLoadingCart: (state) => state.loading,
    getBikeByUid: (state) => (uid) => {
      if (!state.cart) return null;
      const bike = state.cart.items.find((item) => item.product.uid === uid);
      return bike;
    },
    getBikeBySku: (state) => (sku) => {
      if (!state.cart) return null;
      const bike = state.cart.items.find((item) => item.product.sku === sku);
      return bike;
    },
    getLatestCartItem: (state) => {
      if (!state.cart || !state.cart.items.length) return null;
      return state.cart.items.find((item) => item.position === 1);
    },
    getCartId: () => Cookies.get('cart_id') || null,
    getAppliedCoupons: (state) => {
      if (!state.cart || !state.cart.applied_coupons) return [];
      return state.cart.applied_coupons;
    },
    getAppliedDiscount: (state, getters) => {
      let totalDiscount = 0;
      if (!state.cart || !state.cart.prices || !state.cart.prices.discounts)
        return totalDiscount;
      state.cart.prices.discounts.forEach((discount) => {
        totalDiscount = totalDiscount + discount.amount.value;
      });
      const currency = getters.getCartCurrency;
      const locale = getters.getCartLocale;
      return formatPrice(totalDiscount, locale, currency);
    },
    getCartItemsCount: (state) => {
      if (!state.cart || !state.cart.items) return 0;
      return state.cart.items
        .filter((item) => item.product.sku.includes('BBCOLOUR') === false)
        .map((item) => item.quantity)
        .reduce((a, b) => a + b, 0);
    },
    getCartCountry: (state, getters, rootState, rootGetters) => {
      return rootGetters['ayko_localisation/getCountry'];
    },
    getCartCurrency: (state, getters, rootState, rootGetters) => {
      return rootGetters['ayko_localisation/getCurrency'];
    },
    getCartLocale: (state, getters) => {
      const availableCurrencies = config.ayko.localisation.availableCurrencies;
      const locale = availableCurrencies.find(
        (currency) => currency.name === getters.getCartCurrency
      );
      return locale ? locale.defaultLocale : 'en-GB';
    },
    getCartPrices: (state) => {
      if (!state.cart || !state.cart.prices) return null;
      return state.cart.prices;
    },
    getCartIsEmpty: (state) => {
      return !!(state.cart === null || state.cart.items.length === 0);
    }
  }
};
